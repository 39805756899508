import React, { Component } from 'react';

import {ReactComponent as ValuePropRectangle} from "../../Assets/Rectangles/ValuePropRectangle.svg"

import {ReactComponent as Clipboard} from "../../Assets/BenefitsIcons/clipboard.svg"
import {ReactComponent as Code} from "../../Assets/BenefitsIcons/code.svg"
import {ReactComponent as Gift} from "../../Assets/BenefitsIcons/gift.svg"
import {ReactComponent as MessageCircle} from "../../Assets/BenefitsIcons/message-circle.svg"
import {ReactComponent as Monitor} from "../../Assets/BenefitsIcons/monitor.svg"
import {ReactComponent as Users} from "../../Assets/BenefitsIcons/users.svg"


import "./ValueProp.css"

class ValueProp extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className="value-prop-div">
                {/* <div className="value-prop-background-div">
                    <div style={{width : "90%", height : "100%", display: "flex", justifyContent : "flex-end", marginTop : "0vh"}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/social-media-59b42.appspot.com/o/Rectangle%2034%20(2).png?alt=media&token=c1eb529e-7344-4e6b-a68e-876c5bbe7afa" width="112" height="112"></img>
                    </div>
                </div> */}
                <div className="value-prop-title-div" data-aos="fade-up">What You Will Gain</div>

                <div className="value-prop-content-row-div">
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><Users></Users></div>
                        <div className="value-prop-content-item-title">Networking</div>
                        <div className="value-prop-content-item-content">Gain access to a professional network of industry leaders, tech enthusiasts, companies, and future software engineers.</div>
                    </div>
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><Code></Code></div>
                        <div className="value-prop-content-item-title">Employable Skills</div>
                        <div className="value-prop-content-item-content">Our program focuses on developing the most in demand skills and conventions. Our program will prepare you for future employment. </div>
                    </div>
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><Gift></Gift></div>
                        <div className="value-prop-content-item-title">Free Access</div>
                        <div className="value-prop-content-item-content">Unlike many similar software programs/bootcamps, our program is entirely free. Our mission is to provide software experience to all.</div>
                    </div>
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><MessageCircle></MessageCircle></div>
                        <div className="value-prop-content-item-title">Professional Communication</div>
                        <div className="value-prop-content-item-content">As important as technical skills are, we also value transparent communication and agile workflow, thus we put emphasis on how to properly communicate.</div>
                    </div>
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><Clipboard></Clipboard></div>
                        <div className="value-prop-content-item-title">Resume Building</div>
                        <div className="value-prop-content-item-content">Visionary Fellowship is a great addition to your resume/CV. Our program will help you stand out in future job or program applications.</div>
                    </div>
                    <div className="value-prop-content-item" data-aos="fade-up">
                        <div className="value-prop-content-item-image"><Monitor></Monitor></div>
                        <div className="value-prop-content-item-title">Engaged Learning</div>
                        <div className="value-prop-content-item-content">There is no better way to learn something than by applying it. Visionary Fellowship is the best way to learn a new skillset or topic.</div>
                    </div>
                    
                </div>
            </div>
        );
    }
}
 
export default ValueProp;