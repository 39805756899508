import React, { Component } from 'react';
import { Button } from 'antd';

import "./Hero.css"

import {ReactComponent as FellowshipFull} from "../../Assets/FellowshipFull.svg"
import {ReactComponent as Rectangle35} from "../../Assets/Rectangles/Rectangle35.svg"
import {ReactComponent as Rectangle34} from "../../Assets/Rectangles/Rectangle34.svg"
import {ReactComponent as Rectangle7} from "../../Assets/Rectangles/Rectangle7.svg"

import {ReactComponent as Arrow} from "../../Assets/arrow_right_alt.svg"

import WeAid from "../../Assets/Logos/WeAid.png"
import Carizon from "../../Assets/Logos/Carizon.png"
import CheaprEats from "../../Assets/Logos/CheaprEats.png"
import HackGuild from "../../Assets/Logos/HackGuild.png"
import MyPalate from "../../Assets/Logos/MyPalate.png"
import Harled from "../../Assets/Logos/Harled.png"
import VirtualMeet from "../../Assets/Logos/VirtualMeet.png"

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className="hero-div">
                {/* <div className="hero-background-div">
                    <div style={{width : "calc(100% - 10%)", display : "flex", justifyContent : "flex-end"}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/social-media-59b42.appspot.com/o/Rectangle%2035.png?alt=media&token=1f694ac9-b1f5-4db8-ba16-8c5d28cb4539" width="50" height="50"></img>
                    </div>
                    <div style={{marginLeft : "100px", marginTop : "15vh"}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/social-media-59b42.appspot.com/o/Rectangle%2034%20(1).png?alt=media&token=c801685d-08b1-433d-9462-c56be621cec9" width="75" height="75"></img>
                    </div>
                    <div style={{width : "100%", display : "flex", justifyContent : "flex-end", marginTop : "10vh"}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/social-media-59b42.appspot.com/o/Rectangle%207%20(1).png?alt=media&token=fe5832a4-8bb1-4e3d-a31d-f90f5f770d67" width="139" height="139"></img>
                    </div>
                </div> */}
                <div></div>
                <div className="hero-content-div">
                    <div className="hero-logo-div">
                        {/* <FellowshipFull></FellowshipFull> */}
                        <div className="hero-fellowship-logo"></div>
                    </div>
                    <div className="hero-title-div">
                        <div>Real Tech Experiences to Empower Youth</div> 
                    </div>
                    <div className="hero-subtitle-div">
                        <div>
                            Visionary Fellowship is a program for student software engineers, PMs, and designers. 
                            Our philosophy is that to learn, you must do. That’s why we match you with a company and a project in 12 weeks, 
                            providing mentors, workshops, and support to ensure your success. Look out for when applications for the next cohort open!
                        </div>
                    </div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfgNHTT_O1kfpYXsFOobb5C2u9RpLx9DsKceLLvFSCRDKtAIg/viewform" target="_blank" rel="noopener" className="hero-button-div exec-application-container">
                        {/* <Button type="primary" ghost={false} style={{width : "180px", height : "40px", zIndex : "50"}}>Apply Now</Button> */}
                        <div className="exec-application">Exec Applications are open!</div>
                        <Arrow></Arrow>
                    </a>
                </div>
                <div className="hero-partners-div">
                    <div className="hero-partners-content-div">
                        <div className="logo-container-div">
                            <img height="32" className="logo-div" alt="logo" src={Carizon}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="32" className="logo-div" alt="logo" src={WeAid}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="32" className="logo-div" alt="logo" src={CheaprEats}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="32" className="logo-div" alt="logo" src={HackGuild}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="32" className="logo-div" alt="logo" src={MyPalate}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="25" className="logo-div" alt="logo" src={Harled}></img>
                        </div>
                        <div className="logo-container-div">
                            <img height="25" className="logo-div" alt="logo" src={VirtualMeet}></img>
                        </div>
                    </div>
                    <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_kPO0U2.json"  background="transparent"  speed="1"  style={{width : "50px", height : "50px", marginTop : "3vh"}}  loop  autoplay></lottie-player>

                </div>
                
            </div>
        );
    }
}
 
export default Hero;