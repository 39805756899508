import React, { Component } from 'react';
import {Button} from "antd"

import {ReactComponent as Linkedin} from "../../Assets/linkedin.svg"
import {ReactComponent as Instagram} from "../../Assets/instagram.svg"
import {ReactComponent as Email} from "../../Assets/mail.svg"
import {ReactComponent as Word} from "../../Assets/Word.svg"

import {ReactComponent as InstagramH} from "../../Assets/instagramH.svg"
import {ReactComponent as EmailH} from "../../Assets/mailH.svg"
import {ReactComponent as LinkedinH} from "../../Assets/linkedinH.svg"

// import {Link} from 

import "./Footer.css"

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className="footer-div">
                <div className="footer-content-div">
                    {/* <div className="footer-title-content">Become a Visionary Fellow</div>
                    <a href="https://bit.ly/visionaryapplication" target="_blank" rel="noopener">
                        <Button type="primary" style={{width : "180px", height : "40px"}}>Apply now</Button>
                    </a> */}
                    <div className="footer-icons-div" style={{marginBottom : "0px"}}>
                        <a href="https://www.linkedin.com/company/visionary-development-studios/" target="_blank" rel="noopener" className="social-icon linkedin">
                            <Linkedin className="hover-icon"></Linkedin>
                        </a>
                        <a href="https://www.instagram.com/visionary_app/" target="_blank" rel="noopener" className="social-icon instagram">
                            <Instagram className="hover-icon"></Instagram>
                        </a>
                        <a href="mailto:markos@visionary.app" target="_blank" rel="noopener" className="social-icon email">
                            <Email className="hover-icon"></Email>
                        </a>
                    </div>
                </div>
                <div className="footer-bottom-div">
                    <div style={{display : "flex", alignItems : "center", width : "100%", flexWrap : "wrap", height : "fit-content"}}>
                        <div className="footer-logo"></div>
                        <div className="footer-copyright">© Visionary Development Studios 2020. <br className="footer-copyright-linebreak"></br> All rights reserved.</div>
                        <a target="_blank" rel="noopener" href="https://www.visionary.app/privacy-policy" className="footer-privacy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Footer;