import React, { Component } from 'react';

import "./Mentor.css"

import {Button} from "antd"

import {ReactComponent as Mentor1} from "../../Assets/Mentor1.svg"
import {ReactComponent as Mentor2} from "../../Assets/Mentor2.svg"
import {ReactComponent as Mentor3} from "../../Assets/Mentor3.svg"


class Mentor extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className="sponsor-div" style={{paddingBottom : "40px"}}>
            <div className="sponsor-content-div" style={{flexWrap : "wrap"}}>
                <div className="sponsor-content-half" data-aos="fade-up" style={{alignItems : "center"}}>
                    <div style={{height : "80px", marginRight : "30px"}}>
                        {/* <Mentor1></Mentor1> */}
                        <div className="mentor1"></div>
                    </div>
                    <div style={{marginLeft : "30px"}}>
                        {/* <Mentor2></Mentor2> */}
                        <div className="mentor2"></div>
                    </div>
                    <div style={{marginTop : "10px", marginRight : "30px"}}>
                        {/* <Mentor3></Mentor3> */}
                        <div className="mentor3"></div>
                    </div>
                </div>
                <div className="sponsor-content-half" data-aos="fade-up">
                    <div className="sponsor-title-div">Mentor our Visionary Fellows</div>
                    <div className="sponsor-content-text">
                        Our pool of experienced mentors is a critical aspect of our program. As a mentor, 
                        you’ll be essential to the success of all parties involved in the fellowship. You’ll 
                        have the opportunity to share your software, design, and project expertise with our 
                        participants, through consulting, workshops, technical assistance, and lectures.
                    </div>
                    <a href="https://bit.ly/visionarymentor" target="_blank" rel="noopener"><Button type="primary" ghost={true} style={{width : "190px", height : "40px"}}>Become a Mentor</Button></a>
                </div>
                
            </div>
        </div>
        );
    }
}
 
export default Mentor;